







import {  Vue } from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

import EventBus from '@/variables'

export default  Vue.extend({
  name: 'Home',
  data() {
    return {
      eventHub: EventBus,
    }
  },
  created() {
    let loggedIn= localStorage.getItem("loggedIn");
    if (loggedIn=="false") {
      console.log("first time after login");
      localStorage.setItem("loggedIn", "true");
      this.$router.go(0);
    } else {
      console.log("user already logged in");
    }
  },
  components: {
    HelloWorld,
  },
});
