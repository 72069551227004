/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Invoice } from '@/types';
import { settings } from '@/variables';
import { authToken, handleAuthorization } from '@/_helpers/auth-header';

const header= {headers: {Authorization: `bearer ${authToken()}`}, method: "GET"};


export const invoiceService = {
    getInvoicesByStatus,
    getInvoicesByCustomer,
    getInvoicesByDate,
    updateInvoice,
    storeInvoice,
};

function getInvoicesByStatus(status: string) {
    const url= `${settings.baseApi}/invoice/byStatus/${status}`;
    return fetch(url, header).then(handleAuthorization);
}

function getInvoicesByDate(from: string, to: string) {
    let url= `${settings.baseApi}/invoice/byDate/${from}`;
    if (to!=null) {
        url += `/${to}`;
    }
    return fetch(url, header).then(handleAuthorization);
}

function getInvoicesByCustomer(customer: string) {
    const url= `${settings.baseApi}/invoice/byCustomer/${customer}`;
    return fetch(url, header).then(handleAuthorization);
}

function updateInvoice(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/checkin/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleAuthorization);
}

function storeInvoice(data: Invoice) {
    const url= `${settings.baseApi}/invoice`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleAuthorization);
}