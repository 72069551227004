import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/clients',
    name: 'KundInnen',
    component: () => import('../views/Clients.vue'),
  },
  {
    path: '/rooms',
    name: 'Arbeitsmappe',
    component: () => import('../views/Rooms.vue'),
  },
  {
    path: '/planning',
    name: 'Belegungsplan',
    component: () => import('../views/Planning.vue'),
  },
  {
    path: '/invoices',
    name: 'Rechnungen',
    component: () => import('../views/Invoices.vue'),
  },
  {
    path: '/reports',
    name: 'Meldungen',
    component: () => import('../views/Reports.vue'),
  },
  {
    path: '/doctorlist',
    name: 'Arztliste',
    component: () => import('../views/Doctorslist.vue'),
  },
  {
    path: '/login',
    name: 'Logout',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/authorities',
    name: 'Behördenzugriff',
    component: () => import('../views/Authority.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
})

export default router
