/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { userService }  from '@/_services';

const state = {
    all: {}
};

const actions = {
    /*
    getAll( { commit  } ): void {
        commit('getAllRequest');

        userService.getAll()
            .then(
                users => commit('getAllSuccess', users),
                error => commit('getAllFailure', error)
            );
    },*/

    /*
    delete({ commit }, id: any) {
        commit('deleteRequest', id);

        userService.delete(id)
            .then(
                (user: any) => commit('deleteSuccess', id),
                (error: any) => commit('deleteFailure', { id, error: error.toString() })
            );
    }*/
};
interface DeleteParam  {
    id: string,
    error: string
}

const mutations = {
    getAllRequest(state: any) {
        state.all = { loading: true };
    },
    getAllSuccess(state: any, users: any) {
        state.all = { items: users };
    },
    getAllFailure(state: any, error: any) {
        state.all = { error };
    },
    deleteRequest(state: any, id: any) {
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map((user: any) =>
            user.id === id
                ? { ...user, deleting: true }
                : user
        )
    },
    deleteSuccess(state: any, id: any) {
        // remove deleted user from state
        state.all.items = state.all.items.filter((user: any) => user.id !== id)
    },
    deleteFailure(state: any, param: DeleteParam) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        state.all.items = state.items.map((user: any) => {
            if (user.id === param.id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...userCopy } = user;
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: param.error };
            }

            return user;
        })
    }
};

export const users = {
    namespaced: true,
    state,
    actions,
    mutations
};