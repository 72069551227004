/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings } from '@/variables';
import { authToken, handleAuthorization } from '@/_helpers/auth-header';

const header= {headers: {Authorization: `bearer ${authToken()}`}, method: "GET"};


export const reportService = {
    getReports,
    insertReport,
};

function getReports() {
    const url= `${settings.baseApi}/report`;
    return fetch(url, header).then(handleAuthorization);
}

function insertReport(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/report`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    sendReportNotification();
    return fetch(url, requestOptions).then(handleInsertReport);
}

function sendReportNotification() {
    const url= `${settings.printApi}/Notifications?action=report`;
    return fetch(url, header).then((result: Response) => {
        console.log("Response for Report Notification: " + JSON.stringify(result));
    });
}



function handleInsertReport(response: any) {
    return response.text().then((text: string) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}