/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings } from '@/variables';
import { authToken, handleAuthorization } from '@/_helpers/auth-header';
const header= {headers: {Authorization: `bearer ${authToken()}`}, method: "GET"};


export const fileService = {
    getById,
    insertImage,
    getFromClient,
    removeImage,
};


function getById(key: string) {
    const url= `${settings.baseApi}/client/${key}`;
    console.log(url, JSON.stringify(header));
    const data= fetch(url, header).then(handleAuthorization);
    console.log(JSON.stringify(data));
    return data; 
}

function getFromClient(clientKey: string, usedFor: string) {
    const url= `${settings.baseApi}/clientfile/${clientKey}/${usedFor}`;
    console.log(url, JSON.stringify(header));
    return fetch(url, header).then(handleAuthorization);
}
async function insertImage(client: string, imgName: string, imgData: string) {
    const url= `${settings.baseApi}/file/`;
    const url2= `${settings.baseApi}/clientfile/`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const fileObj= {
        name: imgName,
        data: imgData,
        createdAt: new Date(),
        createdBy: "webUser"
    };
    let requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(fileObj)
    };
    console.log(url);
    await fetch(url, requestOptions).then(handleUpdateImage).then(result => {
        console.log("received result for /file "+result._key + ", client " + client);
        const clientFileRec= {
            "_from": settings.dbschema+"_Client/" + client,
            "_to": settings.dbschema+"_File/" + result._key,
            "usedFor": imgName
        }
        console.log("data to transmit: " + JSON.stringify(clientFileRec));
        requestOptions = {
            'method': 'POST',
            headers: headerObj,
            body: JSON.stringify(clientFileRec)
        };
        return fetch(url2, requestOptions).then(result => {
            console.log("received result for /clientFile");
            return result;
        });
    });
    

}

async function removeImage(client: string, imgName: string) {
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    getFromClient(client, imgName).then((result)=> {
        const key= result._key;
        const url2= `${settings.baseApi}/file/` + key;
        const requestOptions = {
            'method': 'DELETE',
            headers: headerObj,
        };
        return fetch(url2, requestOptions).then(result => {
            console.log("received result for delete /clientFile");
            return result;
        });
    });
}


function handleUpdateImage(response: any) {
    return response.text().then((text: string) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}

/*
function handleClientFileResponse(response: any) {
    return response.text().then(text => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });

}*/