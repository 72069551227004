/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CheckIn, Reservation } from '@/types';
import { settings } from '@/variables';
import { authToken, handleAuthorization } from '@/_helpers/auth-header';

const header= {headers: {Authorization: `bearer ${authToken()}`}, method: "GET"};


export const roomService = {
    getAllReservations,
    insertReservation,
    deleteReservation,
    replaceReservation,
    extendReservation,
    addRoom,
    updateReservation,
    checkIn,
    getCheckIns,
    getTodayCheckIns,
    getOpenCheckIns,
    updateCheckin,
    deleteCheckin,
    getAll,
    getFloors,
    changeRoom,
    deleteRoom,
    getFloor,
    getRoom,
    getUnconfirmed,
    confirmReservations,
};

function getAllReservations(today: Date, numDays: number) {
    const url= `${settings.baseApi}/room/reservation/${today}`;
    return fetch(url, header).then(handleAuthorization);
}
function getTodayCheckIns() {
    return getCheckIns(new Date(), 45);
}
function getCheckIns(today: Date, numDays: number) {
    const url= `${settings.baseApi}/checkin`; // /${today}`;
    return fetch(url, header).then(handleAuthorization);
}

function getOpenCheckIns(today: Date, numDays: number) {
    const url= `${settings.baseApi}/checkin/open`; // /${today}`;
    return fetch(url, header).then(handleAuthorization);
}
function getFloors() {
    const url= `${settings.baseApi}/floor`; // /${today}`;
    return fetch(url, header).then(handleAuthorization);
}
function getFloor(floorNr: string) {
    const url= `${settings.baseApi}/floor/${floorNr}`; // /${today}`;
    return fetch(url, header).then(handleAuthorization);
}
function getRoom(rKey: string) {
    const url= `${settings.baseApi}/room/${rKey}`;
    return fetch(url, header).then(handleAuthorization);
}
function getUnconfirmed() {
    const url= `${settings.baseApi}/client/confirmations`;
    return fetch(url, header).then(handleAuthorization);
}
function confirmReservations() {
    const url= `${settings.printApi}/Notifications?action=confirm`;
    return fetch(url, header).then((result: Response) => {
        console.log("Response: " + JSON.stringify(result));
    });
}
function updateCheckin(data: CheckIn) {
    const url= `${settings.baseApi}/checkin/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleUpdateCheckin);
}

function insertReservation(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/reservation`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}


function getAll() {
    const url= `${settings.baseApi}/room`;
    return fetch(url, header).then(handleAuthorization);
}


function updateReservation(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/reservation/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function extendReservation(data: Record<string, unknown>, extendDate: string) {
    const extDat= extendDate.substring(0, 10);
    const url= `${settings.baseApi}/reservation/${extDat}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    data.resDate= (data.resDate as string).substring(0, 10);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);

}

function replaceReservation(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/reservation/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);

}

function addRoom(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/room`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}
function changeRoom(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/room/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}
function deleteRoom(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/room/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'DELETE',
        headers: headerObj,
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function checkIn(data: Record<string, unknown>) {
    const url= `${settings.baseApi}/checkin`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleCheckIn);
}

function deleteReservation(data: Reservation) {
    const url= `${settings.baseApi}/reservation/${data.key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'DELETE',
        headers: headerObj,
        // body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}


function deleteCheckin(data: CheckIn) {
    const url= `${settings.baseApi}/checkin/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'DELETE',
        headers: headerObj,
        // body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function handleInsertReservation(response: any) {
    return response.text().then((text: any) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}

function handleCheckIn(response: any) {
    return response.text().then((text: any) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}
function handleUpdateCheckin(response: any) {
    return response.text().then((text: any) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}