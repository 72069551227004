import { userService } from '../_services';
import router from '@/router';

const usrStr= localStorage.getItem('user');
const user = JSON.parse(""+usrStr);
const state = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };
interface LoginData {
    username: string,
    password: string
}
interface DispatchData {
    dispatch: any,
    commit: any
}
interface ICommit {
    commit: any
}

const actions = {
    login(dispatch: DispatchData, logindata: LoginData) {
        dispatch.commit('loginRequest', logindata.username );
    
        userService.login(logindata.username, logindata.password)
            .then(
                user => {
                    dispatch.commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    dispatch.commit('loginFailure', error);
                    dispatch.dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout(comm: ICommit) {
        userService.logout();
        comm.commit('logout');
    },
    /*
    register(dispatch: DispatchData, user: any) {
        dispatch.commit('registerRequest', user);
    
        userService.register(user)
            .then(
                (user: any) => {
                    commit('registerSuccess', user);
                    router.push('/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', 'Registration successful', { root: true });
                    })
                },
                (error: any) => {
                    commit('registerFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    }*/
};

const mutations = {
    loginRequest(state: any, user: any) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state: any, user: any) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state: any) {
        state.status = {};
        state.user = null;
    },
    logout(state: any) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state: any, user: any) {
        state.status = { registering: true };
    },
    registerSuccess(state: any, user: any) {
        state.status = {};
    },
    registerFailure(state: any, error: string) {
        state.status = {};
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};