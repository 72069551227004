import router from '@/router';

export function authHeader(): string {
    // return authorization header with jwt token
    const userStr= localStorage.getItem('user');
    console.log(userStr);
    const user = JSON.parse(""+userStr);

    if (user && user.jwt) {
        return JSON.stringify({ 'Authorization': 'Bearer ' + user.jwt });
    } else {
        return "";
    }
}
export function authToken(): string {
    // return authorization header with jwt token
    const userStr= localStorage.getItem('user');
    const user = JSON.parse(""+userStr);

    if (user && user.jwt) {
        return user.jwt ;
    } else {
        return "";
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handleAuthorization(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                console.log("not authorized");
                router.push("/login");
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        // console.debug("JSON received: " + JSON.stringify(data));
        return data;
    });
}