

















































import Vue from 'vue'
import EventBus from '@/variables'
import { permissionService } from '@/_services/permission.service'
import { PermissionType } from '@/types'

Vue.prototype.$eventBus= EventBus;

export default  Vue.extend ({
  data() {
    return {
      userName: JSON.parse(""+localStorage.getItem("user")).name,
      eventHub: EventBus,
      writeAccess: false,
    }
  },
  mounted():void {
    permissionService.getPermissions().then((result: PermissionType) => {
        localStorage.setItem('permission', result.permission);
        console.log("Received permissions: " + JSON.stringify(result.permission))
        this.writeAccess= (result.permission=='write');
    })
  },

});
